import React from "react";
import dartboard from "./dartboard.svg";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>My Dart Scores</h1>
        <img src={dartboard} className="App-logo" alt="logo" />
        <h2>Coming soon, please stay tuned!</h2>
      </header>
    </div>
  );
}

export default App;
